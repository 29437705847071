import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import tw, { styled } from 'twin.macro'

import {Layout} from '@components/layout/layout'
import {  ListDisc, MainContainer, RightContainer } from "@components/oferta.style"
import { H1, H3, Paragph } from "@components/layout/style"

const Ol = styled('Ol')`
${tw`list-decimal px-4`}
`

const StandardOchronyMaloletnichTemplate:React.FC = ({data}) => (<Layout data={data}
  prop={{
    canonicalUrl: 'https://www.mediroot.pl/rodo/',
    title: 'Rodo - usługi stomatologiczne | Mediroot',
    description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
  <StaticImage
    src="../assets/images/hero.jpg"
    placeholder="blurred"
    alt="Hero image"
    layout="fullWidth" />

  <MainContainer>
    <RightContainer>
      <H1>
        Standard ochrony małoletnich 
      </H1>
      <H3>Mediroot Urszula Korzeniowska</H3>

      <H1>Rozdział 1</H1>
      <H3>Postanowienia ogólne</H3>
      <Paragph>
      <Ol>
            <li>
            Standardy Ochrony Małoletnich w Mediroot Urszula Korzeniowska z siedzibą główną w Wągrowcu 62-100 przy ul. św. Wojciecha 16, wpisaną do Rejestru Podmiotów Medycznych pod numerem 000000169108 (dalej: Mediroot) zostały opracowane w związku z obowiązkami prawnymi nałożonymi ustawą z dnia 13 maja 2016 r. o przeciwdziałaniu zagrożeniom przestępczością na tle seksualnym i ochronie małoletnich.
            </li>
            <li>
            Ilekroć w niniejszym dokumencie jest mowa o:
            <ListDisc>
                <li>
                krzywdzeniu małoletniego – należy przez to rozumieć popełnienie czynu zabronionego lub czynu karalnego (w tym czynu nieobyczajnego) na szkodę małoletniego przez jakąkolwiek osobę, w tym członka personelu lub przez zagrożenie dobra małoletniego, w tym jego zaniedbywanie;
                </li>
                <li>
                małoletnim – należy przez to rozumieć osobę, która nie ukończyła 18 roku życia;
                </li>
                <li>
                kierownictwie – należy przez to rozumieć kierownika podmiotu leczniczego, jego zastępców, pełnomocników oraz wszelkie osoby mające uprawnienia decyzyjne w podmiocie leczniczym, niezależnie od nazwy ich funkcji;
                </li>
                <li>
                personelu – należy przez to rozumieć wszystkie osoby zatrudnione, współpracujące lub świadczące usługi w Mediroot, które biorą udział w udzielaniu świadczeń zdrowotnych małoletnim lub mogą mieć kontakt z małoletnim bądź uczestniczą w procedurze zatrudniania, niezależnie od wykonywanego zawodu i podstawy prawnej udzielania 	świadczeń	zdrowotnych	lub	wykonywania	obowiązków służbowych;
                </li>
                <li>
                standardach – należy przez to rozumień niniejsze Standardy Ochrony Małoletnich; 
                </li>
                <li>
                zatrudnieniu – należy przez to rozumieć nawiązanie współpracy z jakąkolwiek osobą na podstawie umowy o pracę, powołania, mianowania lub umowy cywilnoprawnej (w tym kontraktowej).
                </li>
                <li>
                Gabinety – należy przez to rozumieć jakikolwiek gabinet prowadzony przez Mediroot Urszula Korzeniowska  
                </li>
            </ListDisc>
            </li>
            <li>
            Obowiązek przestrzegania standardów ma każdy członek personelu oraz kierownictwo podmiotu leczniczego.
            </li>
            <li>
            Dla potrzeb przeciwdziałania krzywdzeniu małoletnich, personel i kierownictwo podejmuje działania określone standardami również w sytuacji zaobserwowania lub powzięcia informacji o krzywdzeniu małoletniego przez dorosłe osoby trzecie lub przez innych małoletnich.   
            </li>
            <li>
            Za wdrożenie, monitorowanie przestrzegania, ocenę realizacji standardów oraz inne zadania określone w standardach odpowiedzialny jest kierownik podmiotu leczniczego.    
            </li>
            <li>
            Przed zatrudnieniem jakiekolwiek osoby, która będzie udzielać świadczeń zdrowotnych małoletnim lub będzie uczestniczyć w ich udzielaniu, należy uzyskać informację z Rejestru Sprawców Przestępstw Na Tle Seksualnym dotyczącą tego, czy dana osoba figuruje w tym rejestrze.
            </li>
            <li>
            Przed zatrudnieniem osoby, która będzie udzielać świadczeń zdrowotnych małoletnim lub będzie uczestniczyć w ich udzielaniu, należy zobowiązać ją do dostarczenia aktualnego zaświadczenia z Krajowego Rejestru Karnego w zakresie przestępstw określonych w rozdziale XIX (przestępstwa przeciwko życiu i zdrowiu) i XXV (przestępstwa przeciwko wolności seksualnej) Kodeksu karnego, w art. 189a (handel ludźmi) i art. 207 (znęcanie się) Kodeksu karnego oraz w ustawie z dnia 29 lipca 2005 r. o przeciwdziałaniu narkomanii lub za odpowiadające tym przestępstwom czyny zabronione określone w przepisach prawa obcego.
            </li>
            <li>
            W przypadku zatrudnienia cudzoziemca, należy przeprowadzić jego weryfikację pod kątem figurowania w rejestrach karalności państwa pochodzenia lub państwa, w którym osoba ta ostatnio zamieszkiwała, w zakresie przestępstw wskazanych w standardzie 8 lub odpowiadających im czynów zabronionych, sankcjonowanym prawem danego państwa. Kierownik podmiotu leczniczemu uprawniony jest ponadto do zobowiązania cudzoziemca do złożenia odpowiedniego oświadczenia o niekaralności.   
            </li>
            <li>
            Zatrudnić w Mediroot można wyłącznie te osoby, które nie figurują w Rejestrze Sprawców Przestępstw Na Tle Seksualnym i które dostarczyły aktualne zaświadczenie z Krajowego Rejestru Karnego potwierdzające, że nie były skazane za przestępstwa, których mowa w standardzie 8.   
            </li>
            <li>
            Każdy członek personelu ma obowiązek zapoznania się ze standardami. Potwierdzeniem zapoznania się ze standardami przez członka personelu jest złożenie przez niego oświadczenia, wzór którego stanowi załącznik nr 1 do standardów.
            </li>
            <li>
            Kierownik podmiotu leczniczego co najmniej raz do roku w grudniu:  
            <ListDisc>
                <li>
                dokonuje przeglądu Rejestru Sprawców Przestępstw Na Tle Seksualnym, ustalając czy członek personelu, który udziela świadczeń zdrowotnych małoletnim lub uczestniczy w ich udzielaniu w podmiocie leczniczym, nie został wpisany do tego rejestru;
                </li>
                <li>
                odbiera oświadczenia od członka personelu, który udziela świadczeń zdrowotnych małoletnim lub uczestniczy w ich udzielaniu w podmiocie leczniczym, o tym, że nie został on wpisany do Krajowego Rejestru Karnego w zakresie przestępstw określonych w standardzie 8 oraz że nie toczy się wobec niego postępowanie przygotowawcze lub sądowe dotyczące tych przestępstw.
Wzór oświadczenia stanowi załącznik nr 2 do standardów.  
                </li>
            </ListDisc>  
            </li>
            <li>
            W przypadku powzięcia informacji o: 
            <ListDisc>
                <li>
                wszczęciu   lub   prowadzeniu   wobec   członka   personelu   postępowania   karnego o przestępstwa określone w standardzie 8, kierownik podmiotu leczniczego niezwłocznie odsuwa takiego członka od wszelkich form kontaktu z małoletnimi;   
                </li>
                <li>
                skazaniu członka personelu za przestępstwo lub przestępstwa określone w standardzie 8, kierownik podmiotu leczniczego niezwłocznie rozwiązuje z nim umowę będącą podstawą zatrudnienia lub odwołuje go ze stanowiska.   
                </li>
            </ListDisc>
            </li>
       </Ol>
      </Paragph>

      <H1>
        Rozdział 2
      </H1>
      <H3>
      Zasady zapewniające bezpieczne relacje między małoletnimi a personelem
      </H3>
      <Paragph>
        <Ol>
           <li>
           Podstawową zasadą czynności podejmowanych przez personel oraz kierownictwo Mediroot w kontaktach z małoletnimi jest działanie na rzecz ich dobra.
           </li> 
           <li>
           Członkowie personelu oraz kierownictwo:
           <ListDisc>
                <li>
                traktują małoletnich z szacunkiem, zachowując w kontakcie z małoletnimi spokój i cierpliwość, a także okazują zrozumienie dla ich trudności, problemów i potrzeb;
                </li>
                <li>
                dbają o przestrzeganie ich praw pacjenta;    
                </li>
                <li>
                respektują ich prawo do opieki ze strony przedstawicieli ustawowych bądź opiekunów faktycznych;    
                </li>
                <li>
                w miarę możliwości tłumaczą im podejmowane działania oraz dążą do uzyskania aprobaty na udzielane świadczenia zdrowotne;    
                </li>
                <li>
                dają możliwość wyrażania przez małoletniego swojego zdania oraz zapewniają małoletniemu prawo do bycia wysłuchanym.    
                </li>
           </ListDisc>
           </li>
           <li>
           Każda osoba zatrudniona w podmiocie leczniczym, która ma świadomość, iż małoletni doznał znęcania fizycznego, psychicznego lub wykorzystania seksualnego, zobowiązany jest do zachowania szczególnej ostrożności i taktu w kontaktach z tym małoletnim. 
           </li>
           <li>
           Jeżeli małoletni swoim postępowaniem dąży do nawiązania z członkiem personelu kontaktu fizycznego o potencjalnie niestosownym bądź nieadekwatnym charakterze, personel podejmuje odpowiednie, stanowcze działania, wyjaśniając z wyczuciem małoletniemu konieczność zachowania granic strefy osobistej. Na badanie małoletniego pacjenta należy uzyskać zgodę osoby uprawnionej według przepisów prawa polskiego, z zastrzeżeniem sytuacji nagłych. 
           </li>
           <li>
           Badanie małoletniego należy wykonać w obecności jego przedstawiciela ustawowego, opiekuna faktycznego, a gdy to nie jest możliwe, w obecności osoby z personelu. 
           </li>
           <li>
           Badanie małoletniego może łączyć się z koniecznością rozebrania pacjenta, oglądania go, dotykania lub penetracji jam ciała wyłącznie w sytuacjach mających uzasadnienie medyczne. 
           </li>
           <li>
           Podczas badania okolic intymnych lub badań połączonych z potrzebą penetracji jam ciała należy zachować szczególny takt i umiar, tłumacząc w miarę możliwości najpierw potrzebę wykonania takich badań i ich planowany przebieg zarówno małoletniemu pacjentowi, jak i obecnemu przy badaniu przedstawicielowi ustawowemu albo opiekunowi faktycznemu. 
           </li>
           <li>
           Podczas badania małoletniego pacjenta należy zapewnić mu intymność adekwatną do jego wieku, potrzeb i oczekiwań.
           </li>
           <li>
           W miarę możliwości kadrowych, badania małoletniego pacjenta, które ingeruje w jego strefę intymną, dokonuje członek personelu tej samej płci.
           </li>
           <li>
           Członkowie personelu zwracają uwagę na czynniki ryzyka i symptomy krzywdzenia małoletnich.
           </li>
        </Ol>
      </Paragph>

      <H1>
        Rozdział 3
      </H1>
      <H3>
      Katalog zachowań zabronionych
      </H3>
      <Paragph>
        <Ol>
           <li>
           Zabrania się stosowania jakiejkolwiek formy lub postaci przemocy wobec małoletnich (zarówno przemocy fizycznej, jak i werbalnej), polegającej w szczególności na stosowaniu kontaktu fizycznego o charakterze agresywnym, krytyki lub obraźliwego bądź dyskryminującego zachowania.
          </li>
          <li>
          Zabrania się dotykania małoletnich w sposób, który mógłby zostać nieprawidłowo zinterpretowany lub który wykracza poza uzasadnioną potrzebę medyczną.
          </li>
          <li>
          Zabrania się prezentowania małoletnim treści o charakterze erotycznym, pornograficznym bądź przemocowym.
          </li>
          <li>
          Zabrania się wyśmiewania małoletnich, poniżania ich lub etykietowania.
          </li>
          <li>
          Kontakt fizyczny z małoletnim nigdy nie może być niejawny bądź ukrywany, wiązać się z jakąkolwiek gratyfikacją ani wynikać z relacji władzy lub autorytetu.
          </li>
          <li>
          Personel nie może kontaktować się bezpośrednio z małoletnim z pominięciem jego przedstawiciela ustawowego.
          </li>
          <li>
          Personel nie może nawiązywać kontaktów z małoletnim w innej niż przewidziana udzielaniem świadczeń zdrowotnych formie, w szczególności poprzez zapraszanie albo przyjmowanie zaproszeń od małoletnich w mediach społecznościowych, komunikatorach lub za pośrednictwem innych prywatnych form kontaktu.
          </li>
        </Ol>
      </Paragph>


      <H1>
        Rozdział 4
      </H1>
      <H3>
      Zasady i procedura podejmowania interwencji w sytuacji podejrzenia krzywdzenia małoletniego oraz osoba odpowiedzialna za przyjmowanie zgłoszeń i informowanie odpowiednich instytucji
      </H3>
      <Paragph>
        <Ol>
           <li>
           W przypadku zaobserwowania lub powzięcia informacji o:
           <ListDisc>
                <li>
                sytuacji przemocy bądź wykorzystania małoletniego – każdy członek personelu lub kierownictwa jest zobowiązany do natychmiastowej reakcji, zmierzającej do powstrzymania zachowań niedozwolonych i zapewnienia ochrony małoletniemu;
                </li>
                <li>
                zachowań rodzących podejrzenie przemocy bądź wykorzystania małoletniego – każdy członek personelu lub kierownictwa jest zobowiązany do zgłoszenia ich kierownikowi podmiotu leczniczego;
                </li>
                <li>
                naruszenia standardów – każdy członek personelu lub kierownictwa jest zobowiązany do niezwłocznego zgłoszenia podejrzenia naruszeń kierownikowi podmiotu leczniczego.
                </li>
           </ListDisc>
          </li>
          <li>
          Podejrzenie naruszenia standardów, w szczególności poprzez krzywdzenie małoletnich, można zgłaszać Urszuli Korzeniowskiej lub na adres e-mailowy: rkorzeniowski@gmail.com.
          </li>
          <li>
          Kierownik podmiotu leczniczego dąży do niezwłocznego wyjaśnienia sprawy zgłoszenia podejrzenia krzywdzenia małoletniego przez członka personelu, podejmując wszelkie niezbędne działania. Podejmowane działania kierownik podmiotu leczniczego dokumentuje.
          </li>
          <li>
          W przypadku, gdy zgłoszono podejrzenie krzywdzenia małoletniego przez członka personelu, członek ten zostaje natychmiast odsunięty od wszelkich form kontaktu z małoletnimi do czasu wyjaśnienia sprawy.
          </li>
          <li>
          W przypadku zaobserwowania, że przedstawiciel ustawowy małoletniego zaniedbuje jego potrzeby psychofizyczne lub rodzina jest niewydolna wychowawczo, stosuje przemoc wobec małoletniego bądź godzi się na jej stosowanie lub w inny sposób krzywdzący małoletniego sobie nie radzi, należy podjąć działania adekwatne do sytuacji.
          </li>
          <li>
          W sytuacji opisanej w standardzie 36, w przypadku:
          <ListDisc>
            <li>
            ubóstwa – można porozmawiać z przedstawicielem ustawowym małoletniego
            i poinformować go o możliwościach wsparcia, w szczególności o ośrodkach pomocy społecznej;             
            </li>
            <li>
            zaniedbania – można poinformować o możliwości wsparcia psychologicznego, w szczególności o telefonach zaufania, poradniach oraz specjalistach, którzy przyjmują na terenie danego powiatu;
            </li>
            <li>
            przemocy – można wszcząć procedurę Niebieskiej Karty.
            </li>
          </ListDisc>
          </li>
          <li>
          W przypadku, gdy z rozmowy z przedstawicielem ustawowym wynika, że nie jest on zainteresowany pomocą małoletniemu, ignoruje zdarzenie bądź stan psychofizyczny małoletniego lub w inny sposób nie wspiera małoletniego, który doświadczył krzywdzenia, kierownik podmiotu leczniczego sporządza wniosek o wgląd w sytuację rodziny, który kieruje do właściwego sądu rodzinnego. Z wniosku sporządza się notatkę.        
          </li>
          <li>
          W przypadku podejrzenia, że zdrowie lub życie małoletniego są zagrożone lub gdy istnieje uzasadnione podejrzenie popełnienia przestępstwa na małoletnim, kierownik podmiotu leczniczego sporządza zawiadomienie o możliwości popełnienia przestępstwa i przekazuje je do właściwej miejscowo policji lub prokuratury. Z zawiadomienia sporządza się notatkę.
          </li>
          <li>
          W miejscu udzielania świadczeń zdrowotnych w przestrzeni dostępnej dla pacjentów wywiesza się informację o ogólnopolskich telefonach pomocy dla osób pokrzywdzonych przestępstwami oraz przemocą w rodzinie:
          <ListDisc>
            <li>
            ogólnopolski telefon dla osób pokrzywdzonych przestępstwem +48 222 309 900;
            </li>
            <li>
            Ogólnopolskie Pogotowie dla Ofiar Przemocy w Rodzinie „Niebieska Linia” 800 120 002;
            </li>
            <li>
            Telefon Zaufania Dla Dzieci i Młodzieży 116 111.
            </li>
          </ListDisc>
          </li>
        </Ol>
      </Paragph>

      

      <H1>
        Rozdział 5
      </H1>
      <H3>
      Zasady aktualizacji Standardów oraz zakres kompetencji osób odpowiedzialnych za przygotowanie członków personelu do ich stosowania
      </H3>
      <Paragph>
        <Ol>
           <li>
           Kierownik podmiotu leczniczego nie rzadkiej niż co dwa lata dokonuje przeglądu standardów w celu ich dostosowania do aktualnych potrzeb oraz zgodności z obowiązującymi przepisami. Wnioski z przeprowadzonej oceny należy udokumentować.           
          </li>
          <li>
          Członkowie personelu, małoletni lub ich przedstawiciele ustawowi mogą przekazywać swoje uwagi do standardów do kierownika podmiotu leczniczego.
          </li>
          <li>
          Kierownik podmiotu leczniczego może sprawdzać znajomość standardów wśród personelu oraz organizować szkolenia z zakresu standardów.
          </li>
         
        </Ol>
      </Paragph>

      

      <H1>
        Rozdział 6
      </H1>
      <H3>
      Zasady udostępniania rodzicom albo opiekunom prawnym lub faktycznym oraz małoletnim standardów do zapoznania się z nimi i ich stosowania
      </H3>
      <Paragph>
        <Ol>
           <li>
           Standardy są dokumentem ogólnodostępnym. Standardy znajdują się:
           <ListDisc>
            <li>
            w budynku podmiotu leczniczego na tablicy informacyjnej;
            </li>
            <li>
                w recepcji oraz
            </li>
            <li>
            na stronie internetowej podmiotu leczniczego pod adresem: https://mediroot.pl 
            </li>
           </ListDisc>
          </li>
          <li>
          Każdy małoletni lub przedstawiciel ustawowy małoletniego może otrzymać kopię standardów dostępnych w recepcji do wglądu.
          </li>
          <li>
          W oparciu o standardy opracowano ich wersję skróconą, zawierającą informacje istotne dla małoletnich. Skrócona wersja standardów stanowi załącznik nr 3.
          </li>         
        </Ol>
      </Paragph>

      <H1>
        Rozdział 7
      </H1>
      <H3>
      Zasady korzystania z urządzeń elektronicznych z dostępem do sieci Internet oraz procedury ochrony małoletnich przed treściami szkodliwymi i zagrożeniami w sieci Internet oraz utrwalonymi w innej formie
      </H3>
      <Paragph>
        <Ol>
           <li>
           Podmiot leczniczy zapewnia dostępu do sieci Internet.
          </li>
          <li>
         Dostęp do sieci Internet jest zabezpieczony hasłem oraz tak skonfigurowany, że uniemożliwia wchodzenie na strony:
          <ListDisc>
            <li>
            o treści pornograficznej lub erotycznej;
            </li>
            <li>
            przedstawiające obrazy przemocy lub okrucieństwa wobec innych ludzi lub zwierząt;
            </li>
            <li>
            witryny hazardowe;
            </li>
            <li>
            niemodyfikowane pokoje czatu.
            </li>  
          </ListDisc>
          </li>   
        </Ol>
      </Paragph>

      <H1>
        Rozdział 8
      </H1>
      <H3>
      Zasady ustalania planu wsparcia małoletniego po ujawnieniu krzywdy
      </H3>
      <Paragph>
        <Ol>
           <li>
           Po ujawnieniu krzywdzenia małoletniego, kierownik podmiotu leczniczego ustala plan jego wsparcia wraz z jego przedstawicielem ustawowym, o ile to nie przedstawiciel dopuszcza się krzywdzenia.
          </li>
          <li>
          O ile to możliwe, po ustaleniu planu wsparcia kierownik podmiotu leczniczego wysłuchuje zdania małoletniego i je uwzględnia.
          </li>   
        </Ol>
      </Paragph>

      <H1>
        Rozdział 9
      </H1>
      <H3>
      Zasady ochrony wizerunku małoletniego
      </H3>
      <Paragph>
        <Ol>
           <li>
           Wizerunek małoletniego podlega ochronie.
          </li>
          <li>
          Upublicznienie wizerunku małoletniego utrwalonego w jakiejkolwiek formie (tj. fotografia, nagranie audio-wideo) wymaga pisemnej zgody przedstawiciela ustawowego tego małoletniego.
          </li>  
          <li>
          Jeżeli wizerunek małoletniego stanowi jedynie szczegół całości, takiej jak zgromadzenie, krajobraz, publiczne wydarzenie, zgoda przedstawiciela ustawowego na utrwalanie wizerunku małoletniego nie jest wymagana.
          </li> 
          <li>
          Wizerunek małoletniego utrwalony za pomocą kamer przemysłowych służących do rejestracji dźwięku lub obrazu na potrzeby zapewnienia bezpieczeństwa osobom i mieniu znajdującym się w podmiocie leczniczym nie podlega upublicznieniu i nie wymaga zgody przedstawiciela ustawowego małoletniego do utrwalania jego wizerunku. Wykorzystanie utrwalonych w ten sposób materiałów ograniczone jest wyłącznie do pierwotnie określonych dla ich rejestracji celów.
          </li>
        </Ol>
      </Paragph>

      <H1>
        Rozdział 10
      </H1>
      <H3>
      Postanowienia końcowe
      </H3>
      <Paragph>
        <Ol>
           <li>
           53. Standardy względem osób zatrudnionych w Mediroot mają moc regulacji wewnętrznych, do przestrzegania których są oni zobowiązani.
          </li>
          <li>
          Standardy wchodzą w życie z dniem ich ogłoszenia. Ogłoszenie standardów odbywa się poprzez ich opublikowanie w formie określonej w rozdziale 6.
          </li>
          <li>
          Kierownik podmiotu leczniczego uprawniony jest do dokonywania zmian standardów. Zmienione standardy wchodzą w życie z dniem ich ogłoszenia w sposób określony w rozdziale 6 
          </li>
        </Ol>
      </Paragph>

  <Paragph>
    <ListDisc>
        <li>
        Zał. 1 OŚWIADCZENIE O ZAPOZNANIU SIĘ ZE STANDARDAMI OCHRONY MAŁOLETNICH W Mediroot Urszula Korzeniowska 
        </li>
        <li>
            Oświadczenie o nie popełnieniu przestępstw
        </li>
    </ListDisc>
  </Paragph>

    </RightContainer>
  </MainContainer>
</Layout>);

export default StandardOchronyMaloletnichTemplate;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
